@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-serif;
  }
  li {
    @apply px-3;
    @apply cursor-pointer;
  }
}
.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}
.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(112, 115, 255, 0.8),
    hsla(242, 74%, 61%, 0.8)
  ) !important;
}
